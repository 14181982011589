import { Alert, Button, Divider, Input, InputNumber, Modal, Select, Space, Typography } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { getAllGHLAccountsSpecificUser, getCustomFieldDetails } from "../../../../../../helpers/GhlApi";
const { Text } = Typography;

const ServicesMtContractCreated = ({ open, setOpen, connectionData, setConnectionData, selectedGhlAccount, selectedMtLocation }) => {
  const [customFields, setCustomFields] = useState([]);
  const [ghlAccountFields, setGhlAccountFields] = useState(null);
  const [localUpdatedClientCreatedData, setlocalUpdatedClientCreatedData] = useState(connectionData?.membershipEvent?.filter((element) => element.ghl_id === selectedGhlAccount && element.locationId === selectedMtLocation)[0] || null);

  const handleCancel = () => {
    setOpen(false);
  };

  console.log(localUpdatedClientCreatedData);

  useEffect(() => {
    const reloadClientSaleDetails = () => {
      const toastBox = toast;
      const getCustomFieldPromise = getCustomFieldDetails({ ghl_id: selectedGhlAccount });
      getCustomFieldPromise
        .then(
          (data) => {
            if (data) {
              console.log(data);
              setCustomFields(data);
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        });
    };
    const getSelectedFieldDetailsFunc = () => {
      const toastBox = toast;
      const getCustomFieldPromise = getAllGHLAccountsSpecificUser();
      getCustomFieldPromise
        .then(
          (data) => {
            if (data && data?.length !== 0) {
              setGhlAccountFields(data?.filter((element) => element.id === selectedGhlAccount)[0] || null);
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        });
    };
    getSelectedFieldDetailsFunc();
    reloadClientSaleDetails();
  }, [connectionData, selectedGhlAccount]);

  const handleSaveButton = () => {
    let letSave = true;
    if (letSave) {
      //  setConnectionData((prevData) => ({ ...prevData, classCalendar: localUpdatedClientCreatedData }))
      setConnectionData((prevData) => {
        let foundMatchingItem = false;

        let updatedClientCreatedData = prevData.membershipEvent.map((item) => {
          if (item.ghl_id === selectedGhlAccount && item.locationId === selectedMtLocation) {
            foundMatchingItem = true;
            return {
              ...item,
              membershipAdd: "true",
              ...localUpdatedClientCreatedData,
            };
          }

          return item;
        });

        if (!foundMatchingItem) {
          setlocalUpdatedClientCreatedData({
            ghl_id: selectedGhlAccount,
            locationId: selectedMtLocation,
            membershipAdd: "true",
            ...localUpdatedClientCreatedData,
          });
          updatedClientCreatedData.push({
            ghl_id: selectedGhlAccount,
            locationId: selectedMtLocation,
            membershipAdd: "true",
            ...localUpdatedClientCreatedData,
          });
        }
        return {
          ...prevData,
          membershipEvent: updatedClientCreatedData,
        };
      });
    } else toast.error(`Check your selection!`);
  };

  const handleCustomTagsInput = (e) => {
    setlocalUpdatedClientCreatedData({ ...localUpdatedClientCreatedData, membershipAddTags: e.target.value });
  };

  const handleTypeFieldSelect = (customFieldID) => {
    setlocalUpdatedClientCreatedData({ ...localUpdatedClientCreatedData, memberFieldID: customFieldID });
  };

  const handleAutoRenewalFieldSelect = (customFieldID) => {
    setlocalUpdatedClientCreatedData({ ...localUpdatedClientCreatedData, statusFieldID: customFieldID });
  };

  const handleActiveSelect = () => {
    setlocalUpdatedClientCreatedData((prevData) => {
      return { ...localUpdatedClientCreatedData, membershipAdd: prevData?.membershipAdd === "true" ? "false" : "true" };
    });
  };

  return (
    <Modal
      title={"Manage membership purchase event"}
      className="max-w-3xl"
      width={"70vw"}
      centered
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Return
        </Button>,
        <Fragment key="save">
          {localUpdatedClientCreatedData && JSON.stringify(localUpdatedClientCreatedData) !== JSON.stringify(connectionData?.membershipEvent.filter((element) => element.ghl_id === selectedGhlAccount && element.locationId === selectedMtLocation)[0]) ? (
            <Button key="save" type="default" className="bg-red-300" onClick={handleSaveButton}>
              Save & continue
            </Button>
          ) : (
            <Button key="save" type="default" className="bg-red-300" disabled>
              Save & continue
            </Button>
          )}
        </Fragment>,
      ]}
    >
      <Divider className="my-0.5" />

      <div className="flex justify-between items-center my-auto">
        <div className="pt-2 my-auto">
          <Typography.Title level={5} type="secondary">
            This event will update GHL custom fields and tags
          </Typography.Title>
        </div>

        <Button key="save" type="dashed" className={`${localUpdatedClientCreatedData?.membershipAdd === "true" ? `bg-green-300` : `bg-gray-100`} `} onClick={() => handleActiveSelect()}>
          {localUpdatedClientCreatedData?.membershipAdd === "true" ? `Active` : `Inactive`}
        </Button>
      </div>

      <Divider className="my-0.5" />
      <div className="grid grid-flow-col mb-6">
        {/* <div className="grid lg:grid-cols-2 grid-cols-1 mt-2 gap-4">
          <div className="grid grid-cols-2 mx-auto text-end lg:text-end justify-start ">
            <Text className="grid-cols-1 text-base min-w-fit">Select name field : </Text>
            <Select
              placeholder="Select a field"
              className="grid-cols-1 max-w-[12rem] text-start justify-start ml-4"
              defaultValue={connectionData?.clientSaleEvent?.length !== 0 ? connectionData?.clientSaleEvent.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.itemNameFieldID : null}
              onChange={(e) => handleNameFieldSelect(e)}
            >
              {customFields.length !== 0 &&
                customFields.map((element) => {
                  return (
                    <Select.Option key={element.id} value={element.id}>
                      {element.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
          <div className="grid grid-cols-2 mx-auto text-end lg:text-end justify-start ">
            <Text className="text-base min-w-fit">Select type field : </Text>
            <Select
              placeholder="Select a field"
              className="grid-cols-1 max-w-[12rem] text-start justify-start ml-4"
              defaultValue={connectionData?.clientSaleEvent?.length !== 0 ? connectionData?.clientSaleEvent.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.itemTypeFieldID : null}
              onChange={(e) => handleTypeFieldSelect(e)}
            >
              {customFields.length !== 0 &&
                customFields.map((element) => {
                  return (
                    <Select.Option key={element.id} value={element.id}>
                      {element.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
          <div className="grid grid-cols-2 mx-auto text-end lg:text-end justify-start ">
            <Text className="text-base min-w-fit">
              Delay
              <Text> (for multiple items) :</Text>
            </Text>
            <InputNumber
              className="grid-cols-1 max-w-[12rem] text-start justify-start ml-4"
              defaultValue={connectionData?.clientSaleEvent?.length !== 0 ? connectionData?.clientSaleEvent.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.delay : 10}
              min={0}
              max={120}
              formatter={(value) => `${value} sec`}
              parser={(value) => value.replace(" sec", "")}
              onChange={onDelayInputChange}
            />
          </div>
        </div> */}
        <Space direction="vertical" className="mt-3">
          <div className="">
            <Text className="text-base min-w-fit">Membership Name : </Text>
            <Select placeholder="Select a custom field" className="w-2/5 ml-4" value={localUpdatedClientCreatedData?.memberFieldID ? localUpdatedClientCreatedData?.memberFieldID : ghlAccountFields?.memberFieldID ? ghlAccountFields?.memberFieldID : null} onChange={(e) => handleTypeFieldSelect(e)}>
              {customFields.length !== 0 &&
                customFields.map((element) => {
                  return (
                    <Select.Option key={element.id} value={element.id}>
                      {element.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
          <div className="">
            <Text className="text-base min-w-fit">Membership Status : </Text>
            <Select
              placeholder="Select a custom field"
              className="w-2/5 ml-4"
              value={localUpdatedClientCreatedData?.statusFieldID ? localUpdatedClientCreatedData?.statusFieldID : ghlAccountFields?.statusFieldID ? ghlAccountFields?.statusFieldID : null}
              onChange={(e) => handleAutoRenewalFieldSelect(e)}
            >
              {customFields.length !== 0 &&
                customFields.map((element) => {
                  return (
                    <Select.Option key={element.id} value={element.id}>
                      {element.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
          <Input addonBefore="Membership purchase tags" value={localUpdatedClientCreatedData?.membershipAddTags} onChange={(e) => handleCustomTagsInput(e)} placeholder="tags (optional)" className="lg:w-1/2" />
        </Space>
      </div>
    </Modal>
  );
};

export default ServicesMtContractCreated;
